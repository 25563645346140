export const OFFICES = [
  {
    name: 'Malta Office',
    address: `Level 1,
Tagliaferro Business Centre,
High Street, Sliema,
SLM 1551, Malta`,
    location: {
      lat: 35.910644,
      long: 14.506016,
    },
  },
  {
    name: 'Netherlands Office',
    address: `The Hague Humanity Hub,
Fluwelen Burgwal 58,
2511 CJ Den Haag`,
    location: {
      lat: 52.079245,
      long: 4.318657,
    },
  },
  {
    name: 'The Philippines Office',
    address: `Sourcefit,
19th Floor Exxa Tower,
Bridgetowne IT Park,
Ugong Norte, C5 Road,
Quezon City,
1110,
Metro Manila,
Philippines`,
    location: {
      lat: 14.607811,
      long: 121.080477,
    },
  },
  {
    name: 'Warsaw Office',
    address: `Finlandzka 10
03-903 Warszawa,
Poland`,
    location: {
      lat: 52.2370568,
      long: 21.0504272,
    },
  },
  {
    name: 'Rwanda Office',
    address: `Westerwelle Startup Haus Fl. 4,
Fairview Building,
KG622 Street, Rugando,
Kigali, Rwanda`,
    location: {
      lat: -1.9552914,
      long: 30.0965273,
    },
  },
  {
    name: 'UK Office',
    address: `Trust Stamp, Hub 8,
Unit 2 The Brewery Quarter,
High St, Cheltenham GL50 3FF,
United Kingdom`,
    location: {
      lat: 51.902676,
      long: -2.076349,
    },
  },
  {
    name: 'Atlanta Office',
    address: `3017 Bolling Way NE,
Floors 1 and 2,
Atlanta, Georgia, 30305`,
    location: {
      lat: 33.837684,
      long: -84.380209,
    },
  },
  {
    name: 'Hillsborough Office',
    address: `128 North Churton Street
Hillsborough,
NC, 27278 USA`,
    location: {
      lat: 36.076302,
      long: -79.099110,
    },
  },
  {
    name: 'Silicon Valley Office',
    address: `Plug and Play,
440 N Wolfe Road Sunnyvale,
CA 94085 USA`,
    location: {
      lat: 37.383917,
      long: -122.012921,
    },
  },
];
